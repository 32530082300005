import React from 'react'

import phone1 from '../../assets/images/png/healthCarePage/iPhoneXFreeMockup1.png'
import phone2 from '../../assets/images/png/healthCarePage/iPhoneXFreeMockup2.png'
import human from '../../assets/images/png/healthCarePage/human.png'

import * as styles from './styles.module.scss'

const HealthCareBlock = () => (
  <div className={styles.healthCareBlock}>
    <div className={styles.healthCareBlockTitle}>
      <p>Medicine is about love, otherwise it is worthless.</p>
      <h1>Health Care</h1>
      <img
        src={
          typeof window !== 'undefined' && window.innerWidth > 530
            ? phone1
            : phone2
        }
        alt="iPhone X"
      />
    </div>
    <div className={styles.healthCareBlockProject}>
      <div className={styles.healthCareBlockProjectContainer}>
        <div className={styles.healthCareBlockProjectContainerText}>
          <h2>About the project</h2>
          <p>
            It is a convenient adapted helper application with an individual
            card of the user with his personal data, and COVID-19 disease
            statistics monitoring system in the region of the user and the
            system of tracking the history of visits and use of QR-code in these
            places.
          </p>
        </div>
        <img src={human} alt="Human health care" />
      </div>
    </div>
  </div>
)

export default HealthCareBlock
