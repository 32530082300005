import React from 'react'

import img1 from '../../assets/images/png/healthCarePage/Onboarding.png'
import img2 from '../../assets/images/png/healthCarePage/medical.png'
import img3 from '../../assets/images/png/healthCarePage/qr-code.png'
import img4 from '../../assets/images/png/healthCarePage/statistic.png'

import * as styles from './styles.module.scss'

const Cards = [
  {
    id: 1,
    category: 'left',
    num: '01',
    imageSrc: img1,
    title: 'Onboarding',
    text:
      'WThe sign-in screen welcomes you with\n' +
      'illustrations with hints. This will help new users get up to speed more quickly',
  },
  {
    id: 2,
    category: 'right',
    num: '02',
    imageSrc: img2,
    title: 'Medical card',
    text:
      'Here the user can see the current information about his activity, which is tracked by the app: water balance, calorie count, sleep mode, number of steps per day. The user can also use it to go to the section with statistics on COVID-19',
  },
  {
    id: 3,
    category: 'left',
    num: '03',
    imageSrc: img3,
    title: 'QR-code\n' + 'scan',
    text:
      'The users, who have received the vaccine, can use their unique QR-code, the history of visiting the places, where the code was used, is also displayed at this screen.',
  },
  {
    id: 4,
    category: 'right',
    num: '04',
    imageSrc: img4,
    title: 'Disease statistics ',
    text:
      'On this screen, the user has access to statistics of incidence and vaccination in his region in percentage terms. It is possible to view statistics for the year and for the current week.',
  },
]

const HealthCareBlockCards = () => (
  <div className={styles.healthCareBlock}>
    <div className={styles.healthCareBlockCards}>
      <div className={styles.healthCareBlockCardsContainer}>
        {Cards?.map((el) => (
          <div>
            {el?.category === 'right' ? (
              <div className={styles.healthCareBlockCardsContainerRevert}>
                <div className={styles.healthCareBlockCardsContainerRevertText}>
                  <img src={el.imageSrc} alt="Image health care" />
                  <div>
                    <div>{el.num}</div>
                    <h3>{el.title}</h3>
                    <p>{el.text}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.healthCareBlockCardsContainerText}>
                <img src={el.imageSrc} alt="Image health care" />
                <div>
                  <div>{el.num}</div>
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default HealthCareBlockCards
